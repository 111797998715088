import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import Spinner from '../../../components/Spinner/Spinner';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../../helpers/hooks/useIsTvDevice';
import { useOnFocusable } from '../../../helpers/hooks/useOnFocusable';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FetchDetails, FetchRequestTypes } from '../../../services/types';
import { applicationResizeSelector } from '../../../store/slices/application-selectors';
import { IStubState } from '../data/formatter';
import Stub from './Stub';

export type StubContainerProps = {
  contentGridStubData?: IStubState;
  onClickParameters?: FetchDetails['onClickParameters'];
  onFocusable?: () => void;
  url?: string;
  isMainTemplate?: boolean;
};

function StubContainer({
  contentGridStubData,
  onClickParameters,
  onFocusable,
  url,
  isMainTemplate,
}: StubContainerProps): JSX.Element | null {
  const isTvDevice = useIsTvDevice();
  const resizeMode = useInvariantSelector(applicationResizeSelector);

  const [{ isLoading, error, data }] = useQueryTemplate<IStubState>(
    url,
    { onClickParameters, template: FetchRequestTypes.Stub, isMainTemplate },
    {
      enabled: !contentGridStubData,
      initialData: contentGridStubData,
    }
  );
  const { text, title, URLImage, button, onClick } =
    contentGridStubData || data || {};

  useOnFocusable(onFocusable, !!data);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorTemplate error={error} />;
  }

  if (!data) return null;

  return (
    <Stub
      text={text}
      title={title}
      URLImage={URLImage}
      onClick={onClick}
      isTvDevice={isTvDevice}
      resizeMode={resizeMode}
      buttonLabel={button?.title}
    />
  );
}

export default StubContainer;
