import { ApiV2PageRubrique } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/gabarit_list/definitions';
import Linker from '../Linker/Linker';
import { LinkerData } from '../Linker/types';
import styles from './GabaritListItem.css';

export type GabaritListItemProps = {
  item: ApiV2PageRubrique;
  id?: string;
  navigateOnFocus?: boolean;
  onClick: () => void;
};

/**
 * GabaritListItem
 * Rendering item in gabarit list. It is decorated with a Linker for TMS purpose
 *
 * @param  {object} item      an object with at least a displayName
 * @param  {string} className a custom className
 * @returns {node}
 */
function GabaritListItem({
  item = {},
  id,
  navigateOnFocus = false,
  onClick = () => null,
}: GabaritListItemProps): JSX.Element {
  const { displayName } = item;

  // Bug if item is destructured
  const data = {
    subOnClick: item as Routing.IOneDiscoveryOnClick,
  } satisfies LinkerData;

  return (
    <Linker
      onClick={onClick}
      className={`${styles.gabaritListNav} gabaritListNav`}
      title={displayName}
      data={data}
      data-navigateonfocus={navigateOnFocus}
      id={id}
      replace
    >
      {displayName}
    </Linker>
  );
}

export default GabaritListItem;
